<template>
  <section class="project-become-owner">
    <section class="hero-small">
      <div>
        <h1 v-if="dataStoryblok">{{dataStoryblok.title_h1}}</h1>
      </div>
    </section>
    <div class="grid-container">
      <div class="intro">
        <h3 v-if="dataStoryblok">{{ dataStoryblok.title_h3 }}</h3>
      </div>

      <div class="grid-x row content-container">
        <div class="cell medium-8">
          <app-accordion v-for="accordeon in dataStoryblok.accordeons" :key="accordeon._uid" >
            <template v-slot:header>
              <h3>
                <template v-if="accordeon.number < 10">0{{ accordeon.number }}</template>
                <template v-else>{{ accordeon.number }}</template>
              </h3>
              <p>{{accordeon.title}}</p>
            </template>
            <template v-slot:content>
              <div v-html="resolver.render(accordeon.paragraph)"></div>
            </template>
          </app-accordion>
        </div>

        <div class="cell auto align-self-top illustration-container">
          <img :src='illustration' alt='Maisons Alysia'/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import illustration from '@/assets/img/pages/become-owner-illu.jpg';
import Storyblok from '@/services/storyblok';
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.cjs';

const resolver = new RichTextResolver();

export default {
  name: 'project-become-owner',

  data() {
    return {
      illustration,
      dataStoryblok: null,
      resolver,
    };
  },
  methods: {
    async getBecomeOwnerPageData() {
      try {
        const { data } = await Storyblok.get('cdn/stories/page-devenir-proprietaire/devenir-proprio-v0', {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
        });
        this.dataStoryblok = data.story.content;
      } catch (err) {
        throw err;
      }
    },
  },
  mounted() {
    this.getBecomeOwnerPageData();
  },
};
</script>

<style lang="sass">
  .project-become-owner
    padding: 0 0 4rem
    background: $medium-bg

    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, .6) 0%, rgba(32, 32, 32, .2) 100%), url(~@/assets/img/pages/land-become-owner.jpg)

    .intro
      margin: 6rem auto 5rem
      padding: 0 1rem
      max-width: 925px
      text-align: center

    .app-accordion
      &:not(:first-of-type)
        margin-top: 10px

      a
        color: $primary
        font-weight: 500
        font-size: 100%
        text-decoration: none

    .illustration-container
      position: sticky
      top: 30px
      @include breakpoint(large up)
        padding-left: 12px

      img
        width: 100%
        border-radius: 8px

  @media all and (max-width: 640px)
    .grid-container
      .intro
        margin: 4rem auto 4rem

    .content-container
      flex-direction: column-reverse!important

      .cell.auto
        flex: 1

      .illustration-container
        position: relative
        top: 0
        margin-bottom: 30px
</style>
